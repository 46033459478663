<template lang="pug">
  include /mixins
  +b.wrap(:class='opacity === "true" ? "wrap-like-product" : "block"')
    +b.block-favourite
      +e.SPAN.ic(
        :data-like='state'
        :data-id='id'
        @click.prevent='addToLike($event)'
        :class='active ? "icon-like-active" : "icon-favourite"'
      )
</template>

<script>

import { favouriteProduct } from '@api/communication.service'
// import { getLike } from '@/js/services/getItems.service'
// import { animationLikes } from '@/js/utils/animation-likes'
import { eventBus } from '@/js/components/Helpers/eventBus'
import { mapActions } from 'vuex'

export default {
  props: ['id', 'opacity', 'state'],
  data() {
    return {
      active: false,
      infoProduct: {
        id_product: Number(this.id),
        // id_product: this.state,
      },
    }
  },
  watch: {
    infoProduct(nval) {
      console.log(nval.id_product)
    },
  },
  mounted() {
    this.getLikeItem()
  },
  methods: {
    ...mapActions('likeQuantity', ['getQuantityOfLikes']),
    getLikeItem() {
      if ('True' === this.state) {
        this.active = true
      }
    },
    addToLike() {
      this.active = !this.active
      // if (this.active) {
      //   animationLikes(event.path[0])
      // }
      // this.infoProduct.id_product = Number(event.path[0].dataset.id)
      favouriteProduct.execute({}, this.infoProduct).then(() => {
        this.getQuantityOfLikes()
        eventBus.$emit('like:change')
      })
    },
  },
}
</script>
