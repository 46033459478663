import { createI18n } from '@/i18n'
import { mapActions } from 'vuex'
import { createRouter } from './js/components/Routes/router'
import { createStore } from './js/store'

export function createApp({ Vue, I18n, Router, Vuex }) {
  const i18n = createI18n({ Vue, I18n })
  const router = createRouter({ Vue, Router });
  const store = createStore({ Vue, Vuex })
  Vue.prototype.$body = document.body
  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
    created() {
      if ('true' === window.isAuthenticated) {
        this.getQuantityOfCart()
      }
      this.getQuantityOfLikes()
    },

    methods: {
      ...mapActions('cartQuantity', [
        'getQuantityOfCart',
      ]),
      ...mapActions('likeQuantity', [
        'getQuantityOfLikes',
      ]),
    },
  })

  return { app, i18n, store, router }
}
