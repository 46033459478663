<template lang="pug">
  include /mixins
  +b.g-row.--justify_center(v-if='array')
    +b.g-cell.g-cols
      +b.wrap-retailers
        +b.block-line-gray
        +b.block-line-gray
        +b.information-about-retailers
          +b.block-retailer-main(
            v-for='info, i in retailers'
            v-if='quantityOfRetailers > 0 && showInfoAboutRetailers'
          )
            +b.block-retailer-second(
              @click='chooseRetailer(info.retailer)'
            )
              +e.SPAN.text.ds-caption.--variant_uppercase.--size_sm.--bold(
                :class='{ active: info.retailer === active }'
              ) {{info.retailer_title}}
              +b.av_space_mt--4
                +e.P.text.ds-caption.--size_sm(v-if='info.address_one') {{info.address_one}}
                +e.P.text.ds-caption.--size_sm(v-if='info.address_two') {{info.address_two}}
              //- +b.av_space_mt--2(v-if='info.retailer_email')
              //-   +e.A.text.ds-caption.--size_2xs(:href="'mailto:' + info.retailer_email") {{_('Email:')}}  {{info.retailer_email}}
              +b.av_space_mt--2(v-if='info.retailer_phones')
                +e.A.text.ds-caption.--size_2xs(:href="'tel:' + info.retailer_phones") {{info.retailer_phones}}
          +b.error-label.LABEL(v-else-if='quantityOfRetailers === 0') {{ _('No realtors found in this region')}}
        +b.block-line-gray
        +b.block-line-gray
</template>

<script>

export default {
  name: 'ShowRetailer',
  props: ['array', 'id'],
  data() {
    return {
      quantityOfRetailers: null,
      active: '',
      retailers: null,
      showInfoAboutRetailers: null,
    }
  },
  mounted() {
    console.log(this.array);
    this.retailers = this.array.retailers
    this.showInfoAboutRetailers = this.array.showRetailers
    this.quantityOfRetailers = this.array.length
  },
  methods: {
    chooseRetailer(idRetailer) {
      this.active = idRetailer
      this.$emit('choseRetailer', {
        id: idRetailer,
      })
    },
  },
  watch: {
    id() {
      this.active = this.id
    },
    array(nval) {
      console.log(nval)
      this.retailers = nval.retailers
      this.showInfoAboutRetailers = nval.showRetailers
      this.quantityOfRetailers = nval.retailers.length
    },
  },
}
</script>
